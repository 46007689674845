import {ReactElement, ReactNode} from "react";

export class HintRenderer {
    private readonly footerEntries: Hints;

    constructor(footerEntries: Hints) {
        this.footerEntries = footerEntries;
    }

    public renderHintRef(key: keyof Hints, callback?: (position: number) => ReactNode): ReactNode | undefined {
        const entry = Object
            .entries(this.footerEntries)
            .filter(([key, value]) => value.condition)
            .map(([key, value], idx) => ({idx, key, hint: value}))
            .find(x =>x.key === key);
        if (entry) {
            return callback ? callback(entry.idx + 1) : <sup>{entry.idx + 1}</sup>
        }
        return undefined
    }

    public renderHint(callback: (position: number, text: string | ReactNode) => ReactNode): ReactNode {
        return <>{Object.values(this.footerEntries).filter(entry=> entry.condition).map(
            (entry, idx) => callback(idx+1, entry.hint)
        )}</>
    }
}

export type Hint = {
    hint: string | ReactElement;
    condition: boolean
}

export type Hints = {
    priceHint: Hint;
    discountHint: Hint;
    sampleHint: Hint;
    zoneHint: Hint;
}
