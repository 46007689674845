import {ReactNode} from "react";

export type Product = {
  id: number;
  name: string;
  category: string;
  subCategory: string;
  namePrefix: string;
  brand: string;
  specialEdition?: string;
  pricePerPiece: number;
  amount: number;
  moq: number;
  variants: Array<ProductVariant>;
  prices: DistriburPriceDefinition | DirectCustomerPriceDefinition;
  regionAvailabilityZoneCode?: string;
};

export type DistriburPriceDefinition = {
  [index in CustomerType]: number;
};

export type DirectCustomerPriceDefinition = {
  [index in CustomerType]: number;
};

export type ProductVariant = {
  id: number;
  ean: string;
  eanSample?: string;
  eanTester?: string;
  upc: string;
  productId: string;
  name: string;
  funfactorySku?: string;
  color: string;
  exactColorName: string;
  thumbnail: string;
  isDarkColor: boolean;
  position: number;
  qty: number;
  calculatedPrice: number;
  calculatedQty: number;
  customerProductCode: string | null;
};

export type CurrencyInfo = {
  code: string;
  baseCurrency: string;
  exchangeRateFactor: number;
  exchangeRateDate: string;
};

export type OrderSheet = {
  exportOptions: {
    customerType: CustomerType;
    currency: string;
    countryCode?: string;
    discount?: number,
    pricelistVersionId?: number,
    pricelist?: {
      id: number,
      name: string,
      shorthand: string | null
    },
  };
  products: Array<Product>;
  currencyInfo: CurrencyInfo;
  reference: string;
  productPricesDontIncludeDiscount?: boolean;
};

export type PricelistVersion = {
  id: number;
  publishedAt: string;
};

export enum CustomerType {
  DIRECT = "Direct",
  DISTRIBUTOR = "Distributor",
}

/**
 * Key = ProductVariantID
 * Number = QTY
 */
export type OrderStorage = { [key: string]: number };

export type ProductForTable = {
  category: string;
  thumbnail: string;
  name: string;
  brand: string;
  color: string;
  colorName: string;
  productId: number;
  variantProductId: string;
  exactColorName?: string;
  ean: string;
  specialEdition?: string;
  isSample: number;
  isTester: number;
  funfactorySku?: string;
  msrp: number;
  price: number;
  moq: number;
  orderQty: number;
  sampleQty: number;
  testerQty: number;
  calculatedQty: number;
  calculatedPriceWithoutDiscountApplied: number;
  customerProductCode: string | null;
  calculatedPriceWithPotentialDiscountApplied: number;
  regionAvailabilityZoneCode: string | undefined;
};

export type TableDataType = {
  disableButtons: boolean;
  priceComposition: {
    products: number;
    samples: number;
    testers: number;
  };
  data: ProductForTable[];
};
export type SortableColumnDef = {
  accessorKey: keyof ProductForTable;
  sortable?: boolean;
}
export type NonSortableColumnDef = {
  accessorKey: null;
  sortable: false;
}
export type ColumnDef = (SortableColumnDef | NonSortableColumnDef) & {
  header: string | ReactNode;
  subHeader?: string;
  sortable?: boolean;
  colSpan?: number;
  selectFilter?: boolean;
  title?: string;
  cell?: (
    key: string,
    row: ProductForTable,
    currency: string,
    exchangeRateFactor: number
  ) => JSX.Element;
};

export type ProductFilter = Partial<Record<keyof ProductForTable, string[]>>

export type FiltersType = {
  text: string;
  select: ProductFilter;
};

export type PricelistSettings = {
  vatRatePercent: number;
};

export type AdditionalCustomerData = {
  customerOrderReference: string;
  notes: string;
};

export type OrderItemsType = { [index: string]: number | {qty: number, qtySample: number, qtyTester: number} };
